import React from 'react';

import Breadcrumbs from '@/components/common/Breadcrumbs';
import FilterableProductsList from '@/components/section/Product/FilterableProductsList';
import Seo from '@/components/seo';
import { useProductsQuery } from '@/query/products';
import { ROUTE_NAMES } from '@/utils/url';

import * as style from './productos.module.scss';

const ProductsPage = ({ ...props }) => {
  const { location } = props;
  const productsQuery = useProductsQuery();
  const products = productsQuery.allGoogleSpreadsheetProducts.edges.map((e) => e.node);
  const breadcrumbs = [{ url: `/${ROUTE_NAMES.PRODUCTS}`, name: 'Productos' }];
  return (
    <>
      <Seo
        title="Productos"
        description={`Descubrí la selección de productos que Visciglio Audio & Instrumentos tiene para vos.`}
      />
      <section className={style.main}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <FilterableProductsList title="Productos" products={products} location={location} />
      </section>
    </>
  );
};

export default ProductsPage;
